<template lang="pug">
  section
    Card.m-b-25(dis-hover)
      | {{ selected_type }}
    Card.m-b-25(:padding="25" dis-hover)
      Row(:gutter="16")
        iCol.text-center(span="6")
          .num.amount 0.00
          p.hint-text 余额
        iCol.text-center(span="6")
          .num 0
          p.hint-text 积分
        iCol.text-center(span="6")
          .num 0
          p.hint-text 金币
        iCol.text-center(span="6")
          .num 0
          p.hint-text 钻石

    Card.m-b-25(:title="$t('transactions flow')" :padding="0" dis-hover)
      div(slot="extra")
        Select(v-model="selected_type" size="small" style="width:100px")
          Option(:value="item.value" v-for="(item, index) in transaction_types" :key="index") {{ item.label }}
      Table(:columns="columns" :data="user.data" style="width:100%;")
      .padding-15.text-center
        Page(show-total show-sizer
          :current="user.current_page"
          :total="user.total"
          :page-size="user.per_page"
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange")
          //-  v-if="user.total > user.per_page"
    
    Modal(v-model="modal.show"
      :width="modal.width"
      :mask-closable="modal.maskClosable"
      :footer-hide="modal.footerHide"
      :class-name="modal.class"
      :loading="modal.loading"
      @on-visible-change="modalVisibleHandle")
      component(:is="component.is" :data="component.data" :rules="rules")
</template>

<script>
import { mapState } from 'vuex'
// import querystring from 'querystring'

export default {
  name: 'AssetView',
  components: {},
  data() {
    return {
      selected_type: '',
      transaction_types: [
        { label: '余额', value: 'money' },
        { label: '积分', value: 'score' },
        { label: '金币', value: 'coin' },
        { label: '钻石', value: 'diamond' }
      ],
      columns: [
        {
          title: 'id',
          key: 'id'
        },
        {
          title: 'time',
          key: 'time'
        },
        {
          title: 'type',
          key: 'transaction_type'
        },
        {
          title: 'amount',
          key: 'amount'
        },
        {
          title: 'balance',
          key: 'balance'
        },
        {
          title: 'transaction to',
          key: 'transaction_to'
        },
      ],
      rules: {
        name: [
          { required: true, message: 'Please fill in the user name', trigger: 'blur' }
        ]
      },

      /*********************************************************
      * @description 应用数据
      *********************************************************/
      user: {
        current_page: 1,
        data: [],
        last_page: 0,
        per_page: 10,
        total: 0,
      },

      /*********************************************************
      * @description 窗体对象
      *********************************************************/
      modal: {
        width: 680,
        show: false,
        loading: true,
        footerHide: true,
        maskClosable: false,
        class: 'body-no-padding vertical-center-modal',
      },
      component: {
        is: '',
        data: {}
      }
    }
  },
  filters: {},
  computed: {
    ...mapState({
      //- table: state => state.table
    }),
    route_name() {
      return this.$route.name;
    }
  },
  methods: {
    /*********************************************************
     * @description 获取列表数据
     *********************************************************/
    getData() {
      this.$http.get('user', {
        params: {
          page: this.user.current_page,
          limit: this.user.per_page
        }
      }).then(res => {
        this.user = res;
      });
    },

    /*********************************************************
     * @description 翻页
     *********************************************************/
    pageChange(page) {
      this.user.current_page = page;
      this.getData();
    },

    /*********************************************************
     * @description 改变每页显示数量
     *********************************************************/
    pageSizeChange(pageSize) {
      this.user.per_page = pageSize;
      this.getData();
    },

    /*********************************************************
     * @description 弹窗
     *********************************************************/
    routerHandle() {
      switch(this.route_name) {
        default:
          this.getData();
          this.modal.show = false;
      }
    },

    /*********************************************************
     * @description 窗体状态改变
     *********************************************************/
    modalVisibleHandle(visible) {
      if (visible === false && this.route_name !== 'ApplicationManager') {
        this.$router.replace({ name: `ApplicationManager` });
      }
    },
  },
  watch: {
    route_name: {
      handler() {
        this.routerHandle();
      },
      immediate: true,
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.num {
  font-size: 24px; line-height: 1.35;
}
.amount {
  &::before { font-size: 65%; content: '￥';}
}
</style>